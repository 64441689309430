import React from "react";
import Label from "@components/containers/home/label";
import { TAboutUsCard } from "./types";
import Router from "next/router";
import Params from "@utilities/param";

const AboutUsCard = (props: TAboutUsCard) => {
  return (
    <div
      className={`flex flex-col h-40 lg:h-60 xl:h-48 pt-5 px-5 ${
        !props.last && "border-b"
      } ${props.forceBorder && "border-b lg:border-0"} border-gray-200`}
    >
      <img alt="RemoteDerm Icon" loading="lazy" src={props.icon} className="h-14 w-14 mb-3" />
      <span className="mb-2 font-sans-pro-semi-bold font-semibold font-size-16 color-150910-secondary-1">
        {props.title}
      </span>
      <span className="font-sans-pro-regular color-636363-secondary-2 font-size-14">
        {props.description}
      </span>
    </div>
  );
};

type AboutUsProps = {
  aboutUs?: string;
};

const AboutUs = ({ aboutUs }: AboutUsProps) => {
  return (
    <div className="flex flex-col lg:flex-row px-6 lg:px-16 mb-20 lg:mb-44">
      <div className="flex flex-1 flex-col">
        <Label title={"Online Dermatology"} />
        <h2 className="mt-2 mb-5 font-sans-pro-regular text-2xl md:text-4xl color-150910-secondary-1">
          Why using{" "}
          <span className="font-sans-pro-semi-bold font-semibold">
            RemoteDerm
          </span>
          ?
        </h2>
        <p className="pr-0 lg:pr-32 mb-10 font-sans-pro-regular color-636363-secondary-2 font-size-16">
          {aboutUs ||
            `It takes 4-6 months to see a dermatologists in major Canadian cities
          (Vancouver, Edmonton, Calgary, Toronto, Ottawa, Saskatoon). Over the
          counter skincare products can cost $50-$200, you may not even obtain
          the right product. It only takes few hours with minimal fee at
          RemoteDerm to virtually get expert consult from a dermatologist.`}
        </p>
        <div
          onClick={() => {
            const user = localStorage.getItem(
              Params.LOCAL_STORAGE.PATIENT_INFO
            );
            if (user) {
              window.location.href = process.env.REACT_APP_PATIENT_URL!;
            } else {
              Router.push("/pages/auth?type=login");
            }
          }}
          className="cursor-pointer mb-12 h-14 flex w-max items-center font-sans-pro-regular rounded-lg border border-transparent justify-center px-8 text-white bg-color-c56183-eye-catching"
        >
          {"Get Consultation"}
        </div>
      </div>
      <div className="flex  flex-1 flex-col lg:flex-row">
        <div className="flex flex-1 flex-col lg:border-r border-gray-200">
          <AboutUsCard
            title={"No need for an office visit"}
            icon={"https://cdn.remotederm.ca/icons/home/easy_access.webp"}
            description={
              "You no longer need to go to the dermatologist office & can visit virtually here."
            }
          />
          <AboutUsCard
            title={"Convenient access to medications"}
            icon={"https://cdn.remotederm.ca/icons/home/pharmacy.webp"}
            description={
              "The medication is automatically sent to your pharmacy and you can quickly start the treatment."
            }
          />
          <AboutUsCard
            title={"Easy follow up available"}
            icon={"https://cdn.remotederm.ca/icons/home/follow_up.webp"}
            description={
              "You can easily follow up with your dermatologist to get further assistance with your skin care."
            }
            last={true}
            forceBorder={true}
          />
        </div>
        <div className="flex  flex-1 flex-col">
          <AboutUsCard
            title={"Leading dermatologist"}
            icon={"https://cdn.remotederm.ca/icons/home/leading_dermatologist.webp"}
            description={
              "At RemoteDerm, we have the best dermatologists who will be with you until the end of the service."
            }
          />
          <AboutUsCard
            title={"Three steps to booking"}
            icon={"https://cdn.remotederm.ca/icons/home/three_step.webp"}
            description={
              "With three steps, you can easily connect with a dermatologist and start your treatment."
            }
          />
          <AboutUsCard
            title={"Affordable pricing"}
            icon={"https://cdn.remotederm.ca/icons/home/pair_prices.webp"}
            description={
              "You save money by not taking time off from work, traveling or purchasing expensive OTC skincare products."
            }
            last={true}
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
