import React from "react";
import { IProps } from './types'
import Label from '@components/containers/home/label'
import {
    setModalState
} from "@redux/actions/contactUs";
import { useDispatch } from "react-redux";
import Router from "next/router";

const featureList = [
    'Ask about general acne treatment',
    'Ask about general skin pigmentation treatment',
    'Ask about best sunscreen and moisturizer to use',
    'Ask about about skin care products',
    'Ask about best shampoo for your hair'
]

const ContactUs = (props: IProps) => {
    const dispatch = useDispatch();
    return (
        <div className="mb-40 flex flex-col lg:flex-row items-center pt-12 lg:pt-20 pb-12 lg:pb-16 px-6 lg:px-10 xl:px-16 bg-color-f8f8f8">
            <div className="flex flex-1 flex-col items-start">
                <Label title={'Contact us'} />
                <span className="mt-2 mb-5 font-sans-pro-regular text-2xl md:text-4xl color-150910-secondary-1"><span className="font-sans-pro-semi-bold font-semibold">General questions</span> about skin care <br className="hidden xl:block" /> products? We have an answer for <br className="hidden xl:block" /> you </span>
                <p className="mb-8 font-sans-pro-regular color-636363-secondary-2 font-size-16">Our dermatologist will provide a complementary answer to one general question about a skin care product.</p>
                <div
                    onClick={() => Router.push('/pages/contact-us')}
                    className="cursor-pointer mb-12 h-14 flex items-center font-sans-pro-regular rounded-lg border border-transparent justify-center px-8 text-white bg-color-c56183-eye-catching">
                    {'Submit Your Question'}
                </div>
            </div>
            <div className="flex flex-1 w-full items-center justify-start lg:justify-center">
                <div className="absolute hidden lg:block">
                    <img
                        alt="hidden spacer image"
                        src={'https://cdn.remotederm.ca/icons/home/card-bg.webp'}
                    />
                </div>
                <div className="bg-white relative rounded-lg pt-10 pb-6 px-8 shadow-lg lg:shadow-sm">
                    {featureList.map((item: string, index: number) => (
                        <div
                            className="flex flex-row items-start mb-4"
                            key={index}>
                            <img
                                alt="Green checkmark icon"
                                className="w-6 h-6 mr-2"
                                src={'https://cdn.remotederm.ca/icons/home/check_green.webp'} />
                            <span className="font-sans-pro-regular font-size-16 color-150910-secondary-1">{item}</span>
                        </div>
                    ))}
                    <div
                        onClick={() => Router.push('/pages/contact-us')}
                        className="mt-6 rounded-lg cursor-pointer px-12 py-5 bg-color-efefef font-sans-pro-semi-bold font-semibold text-xl lg:text-2xl">
                        {'Complimentary Service'}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
