import React from "react";
import { IProps } from './types'
import Label from '@components/containers/home/label'
import Router from "next/router";
import Params from '@utilities/param'
import {
    setModalState
} from "@redux/actions/contactUs";
import { useDispatch } from "react-redux";



const Country = (props: IProps) => {
    const dispatch = useDispatch(); 
    return (
        <div className="pb-24 pt-12 lg:pt-0 lg:pb-0 flex flex-col-reverse bg-color-150910-secondary-1 home-country-container lg:flex-row px-6 lg:px-16 mb-20 lg:mb-44">
            <div className="flex mt-24 lg:mt-0  flex-1 justify-center items-center">
                <div className="image-container">
                    <img
                        alt="Map of Canada"
                        src={'https://cdn.remotederm.ca/icons/home/canada-map.webp'}
                    />
                </div>
            </div>
            <div className="flex flex-1 justify-center items-center">
                <div className="flex w-10/12 flex-col">
                    <Label dark={true} title={'Location'} />
                    <h2 className="mt-2 mb-5 font-sans-pro-regular text-2xl md:text-4xl text-white">Serving Alberta, British Columbia, Ontario, New Brunswick, Newfoundland and Labrador, Northwest Territories, Nova Scotia, Nunavut, Prince Edward Island, Yukon</h2>
                    <p className="mb-10 font-sans-pro-regular color-FFFFFF80 font-size-16">It takes 4-6 months to see a dermatologists in major Canadian cities (Vancouver, Edmonton, Calgary, Toronto, Ottawa, Saskatoon). Over the counter skincare products can cost $50-$200, you may not even obtain the right product. It only takes few hours with minimal fee at RemoteDerm to virtually get expert consult from a dermatologist.</p>
                    <div className="flex flex-row flex-wrap items-center">
                        <div
                            onClick={() => Router.push("/locations")}
                            className="cursor-pointer h-14 flex w-max items-center font-sans-pro-regular rounded-lg border border-transparent justify-center px-8 text-white bg-color-c56183-eye-catching mr-4 mb-4 lg:mb-0">
                            {'See All Locations'}
                        </div>
                        <div
                            onClick={() => {
                                const user = localStorage.getItem(Params.LOCAL_STORAGE.PATIENT_INFO)
                                if (user) {
                                    window.location.href = process.env.REACT_APP_PATIENT_URL!;
                                }
                                else {
                                    Router.push("/pages/auth?type=login");
                                }
                            }}
                            className="cursor-pointer  h-14 flex w-max items-center font-sans-pro-regular rounded-lg border border-color-FFFFFF80 justify-center px-8 text-white mb-4 lg:mb-0">
                            {'Get Consultation'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Country;
