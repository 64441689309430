import React from "react";
import { IProps } from "./types";
import Label from "@components/containers/home/label";
import Router from "next/router";
import Params from "@utilities/param";
import useDeviceSize from "@utilities/useDeviceSize";

const Specialists = (props: IProps) => {
  const [width] = useDeviceSize();
  return (
    <div className="flex flex-col lg:flex-row px-0 lg:px-16 mb-20 lg:mb-44">
      <div className="home-specialists-container relative hidden lg:block">
        <div className="w-full h-full absolute index-z-1 flex justify-center items-end">
          <div className="bg-color-dff3ec w-96 h-5/6" />
        </div>
        <div className="w-full h-full absolute index-z-2 flex justify-end items-start">
          <div className="w-3/5 home-specialists-container-img relative">
            <img
              loading="lazy"
              className="h-full w-full object-cover"
              alt="A beautiful smiling doctor"
              src={"https://cdn.remotederm.ca/landing_assets/beautiful-smiling-female-doctor.webp"}
            />
            {/* <div className="rounded-lg bg-color-15091080 m-4 flex items-center justify-center font-sans-pro-regular font-size-14 absolute index-z-4 bottom-0 right-0 h-11 px-4 text-white">
                            Photo by <a href={'https://unsplash.com/photos/FVh_yqLR9eA'} target='_blank' className="font-sans-pro-semi-bold font-semibold underline cursor-pointer mx-1">humberto</a>  on <a href={'https://unsplash.com'} target='_blank' className="font-sans-pro-semi-bold font-semibold underline cursor-pointer mx-1">Unsplash</a>
                        </div> */}
          </div>
        </div>
        <div className="h-full w-3/5 absolute index-z-3 flex justify-start items-end">
          <div className="bg-color-150910-secondary-1  mb-24 flex flex-col px-12 py-12">
            <Label dark={true} title={"About us"} />
            {width >= 1024 && (
              <span className="mt-2 mb-5 font-sans-pro-regular text-2xl md:text-4xl text-white">
                A hand picked network of{" "}
                <span className="font-sans-pro-semi-bold font-semibold">
                  doctors and specialists
                </span>{" "}
                ready to help you.{" "}
              </span>
            )}
            <p className="mb-10 font-sans-pro-regular color-FFFFFFCC font-size-16">
              Our practitioners are Canadian board certified and thrive to
              provide the highest quality of skin care to their patients.
            </p>
            <div className="flex flex-row items-center">
              <div
                onClick={() => Router.push("/pages/our-team")}
                className="cursor-pointer mb-12 h-14 flex w-max items-center font-sans-pro-semi-bold font-semibold rounded-lg border border-transparent justify-center px-8 text-white bg-color-c56183-eye-catching mr-4 mb-2 lg:mb-0"
              >
                {"Our Team"}
              </div>
              <div
                onClick={() => {
                  const user = localStorage.getItem(
                    Params.LOCAL_STORAGE.PATIENT_INFO
                  );
                  if (user) {
                    window.location.href = process.env.REACT_APP_PATIENT_URL!;
                  } else {
                    Router.push("/pages/auth?type=login");
                  }
                }}
                className="cursor-pointer  mb-12 h-14 flex w-max items-center font-sans-pro-semi-bold font-semibold rounded-lg border border-color-FFFFFF80 justify-center px-8 text-white mb-2 lg:mb-0"
              >
                {"Get Consultation"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:hidden">
        <div className="bg-color-150910-secondary-1 w-full flex flex-col px-6 py-12">
          <Label dark={true} title={"About us"} />
          {width < 1024 && (
            <span className="mt-2 mb-5 font-sans-pro-regular text-2xl md:text-4xl text-white">
              A hand picked network of{" "}
              <span className="font-sans-pro-semi-bold font-semibold">
                doctors and specialists
              </span>{" "}
              ready to help you.{" "}
            </span>
          )}
          <p className="mb-10 font-sans-pro-regular color-FFFFFFCC font-size-16">
            Our practitioners are Canadian board certified and thrive to provide
            the highest quality of skin care to their patients.
          </p>
          <div className="flex flex-row flex-wrap items-center">
            <div
              onClick={() => Router.push("/pages/our-team")}
              className="cursor-pointer  h-14 flex w-max items-center font-sans-pro-regular rounded-lg border border-transparent justify-center px-8 text-white bg-color-c56183-eye-catching mr-4 mb-4 lg:mb-0"
            >
              {"Our Team"}
            </div>
            <div
              onClick={() => {
                const user = localStorage.getItem(
                  Params.LOCAL_STORAGE.PATIENT_INFO
                );
                if (user) {
                  window.location.href = process.env.REACT_APP_PATIENT_URL!;
                } else {
                  Router.push("/pages/auth?type=login");
                }
              }}
              className="cursor-pointer  h-14 flex w-max items-center font-sans-pro-regular rounded-lg border border-color-FFFFFF80 justify-center px-8 text-white mb-4 lg:mb-0"
            >
              {"Get Consultation"}
            </div>
          </div>
        </div>
        <div className="w-full home-specialists-container-img relative">
          <img
            loading="lazy"
            alt="Beautiful smiling female doctor"
            className="h-full w-full object-cover"
            src={"https://cdn.remotederm.ca/landing_assets/beautiful-smiling-female-doctor.webp"}
          />
          {/* <div className="rounded-lg bg-color-15091080 m-4 flex items-center justify-center font-sans-pro-regular font-size-14 index-z-3 absolute bottom-0 right-0 h-11 px-4 text-white">
                        Photo by <a href={'https://unsplash.com/photos/FVh_yqLR9eA'} target='_blank' className="font-sans-pro-semi-bold font-semibold underline cursor-pointer mx-1">humberto</a>  on <a href={'https://unsplash.com'} target='_blank' className="font-sans-pro-semi-bold font-semibold underline cursor-pointer mx-1">Unsplash</a>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default Specialists;
