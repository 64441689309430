import React from "react";
import Label from "@components/containers/home/label";
import { TBenefitsCard, PricingProps } from "./types";
import Router from "next/router";
import Params from "@utilities/param";

const BenefitsCard = (props: TBenefitsCard) => {
  return (
    <div className="h-10 w-36 flex flex-row mb-6">
      <img
        alt="RemoteDerm Icon"
        loading="lazy"
        src={props.icon}
        className="h-10 w-10 mr-3"
      />
      <span className="font-sans-pro-regular color-150910-secondary-1 font-size-14">
        {props.title}
      </span>
    </div>
  );
};

const Pricing = (props: PricingProps) => {
  return (
    <div className="flex flex-col lg:flex-row px-6 lg:px-16 mb-20 lg:mb-44">
      <div className="flex flex-1 flex-col">
        <Label title={"Pricing"} />
        <h3 className="mt-2 mb-5 font-sans-pro-regular text-2xl md:text-4xl color-150910-secondary-1">
          Simple{" "}
          <span className="font-sans-pro-semi-bold font-semibold">pricing</span>{" "}
          to fit your needs
        </h3>
        <p className="pr-0 lg:pr-32 mb-10 font-sans-pro-regular color-636363-secondary-2 font-size-16">
          If you need immediate assistance with your skin condition then select
          the 24 Hrs or Express Care package. Our dermatologists thrive to address
          your concerns expediently.
        </p>
        <span className="mb-5 font-sans-pro-semi-bold font-semibold color-150910-secondary-1 font-size-18">
          {"The benefits that come with packages"}
        </span>
        <div className="flex flex-col lg:flex-row items-start lg:items-center">
          <BenefitsCard
            icon={"https://cdn.remotederm.ca/icons/home/supporting.webp"}
            title={"24/7 support"}
          />
          <div className="mr-6" />
          <BenefitsCard
            icon={"https://cdn.remotederm.ca/icons/home/secure_platform.webp"}
            title={"Secure platform"}
          />
        </div>
        <div className="flex flex-col lg:flex-row items-start lg:items-center">
          <BenefitsCard
            icon={"https://cdn.remotederm.ca/icons/home/chat.webp"}
            title={"Direct access to a dermatologist"}
          />
          <div className="mr-6" />
          <BenefitsCard
            icon={"https://cdn.remotederm.ca/icons/home/upload_picture.webp"}
            title={"Upload several images"}
          />
        </div>
        <div className="flex flex-col lg:flex-row items-start lg:items-center">
          <BenefitsCard
            icon={"https://cdn.remotederm.ca/icons/home/follow_up.webp"}
            title={"Easy follow up available"}
          />
          <div className="mr-6" />
          <BenefitsCard
            icon={
              "https://cdn.remotederm.ca/icons/home/download_prescriptions.webp"
            }
            title={"Download your medication"}
          />
        </div>
      </div>
      <div className="flex  flex-1 flex-col mt-8 mx-0 lg:mx-12 xl:mx-20">
        <div className="shadow-lg flex flex-col bg-white rounded-lg p-6 mb-4">
          <span className="font-sans-pro-regular color-150910-secondary-1 font-size-24 mb-2">
            {props?.plans?.find((x) => x.title === "72 Hour Service")?.title}
          </span>
          <span className="font-sans-pro-regular color-636363-secondary-2 font-size-14 mb-6">
            {
              props?.plans?.find((x) => x.title === "72 Hour Service")
                ?.description
            }
          </span>
          <span className="mb-4 font-sans-pro-regular color-150910-secondary-1">
            <span className="font-size-28 font-sans-pro-semi-bold font-semibold">
              {props?.plans?.find((x) => x.title === "72 Hour Service")?.price}
            </span>{" "}
            <span className="font-size-24">CAD</span>
            <span className="font-size-14"> /per consult</span>
          </span>
          <div className="flex flex-wrap flex-row">
            <div
              onClick={() => {
                const user = localStorage.getItem(
                  Params.LOCAL_STORAGE.PATIENT_INFO
                );
                if (user) {
                  window.location.href = process.env.REACT_APP_PATIENT_URL!;
                } else {
                  Router.push("/pages/auth?type=login");
                }
              }}
              className="cursor-pointer  h-14 mr-3 flex w-max items-center font-sans-pro-regular rounded-lg border border-gray-300 justify-center px-8 color-150910-secondary-1 lg:mb-0 mb-4"
            >
              {"Get Consultation"}
            </div>
          </div>
        </div>
        <div className="shadow-lg bg-white flex flex-col rounded-lg p-6 pr-0 mb-4">
          <div className="flex flex-row items-center justify-between mb-2">
            <span className="font-sans-pro-regular color-150910-secondary-1 font-size-24">
              {props?.plans?.find((x) => x.title === "24 Hour Service")?.title}
            </span>
            <div className="py-2 px-3 pl-4 rounded-l-full bg-color-A0DCC780 color-3a7661 font-size-14 font-sans-pro-semi-bold font-semibold">
              {"Best choice"}
            </div>
          </div>
          <div className="flex flex-col pr-6">
            <span className="font-sans-pro-regular color-636363-secondary-2 font-size-14 mb-6">
              {
                props?.plans?.find((x) => x.title === "24 Hour Service")
                  ?.description
              }
            </span>
            <span className="mb-4 font-sans-pro-regular color-150910-secondary-1">
              <span className="font-size-28 font-sans-pro-semi-bold font-semibold">
                {
                  props?.plans?.find((x) => x.title === "24 Hour Service")
                    ?.price
                }
              </span>{" "}
              <span className="font-size-24">CAD</span>
              <span className="font-size-14"> /per consult</span>
            </span>
            <div className="flex flex-wrap flex-row">
              <div
                onClick={() => {
                  const user = localStorage.getItem(
                    Params.LOCAL_STORAGE.PATIENT_INFO
                  );
                  if (user) {
                    window.location.href = process.env.REACT_APP_PATIENT_URL!;
                  } else {
                    Router.push("/pages/auth?type=login");
                  }
                }}
                className="cursor-pointer  h-14 mr-3 flex w-max items-center font-sans-pro-regular rounded-lg border border-transparent bg-color-c56183-eye-catching justify-center px-8 text-white lg:mb-0 mb-4"
              >
                {"Get Consultation"}
              </div>
            </div>
          </div>
        </div>
        {props?.plans?.find((x) => x.title === "Express Care Service") && (
          <div className="shadow-lg bg-white flex flex-col rounded-lg p-6 pr-0 mb-4">
            <div className="flex flex-row items-center justify-between mb-2">
              <span className="font-sans-pro-regular color-150910-secondary-1 font-size-24">
                {
                  props?.plans?.find((x) => x.title === "Express Care Service")
                    ?.title
                }
              </span>
              <div className="py-2 px-3 pl-4 rounded-l-full bg-color-b4cef3 color-3c5981 font-size-14 font-sans-pro-semi-bold font-semibold">
                {"Express Plan"}
              </div>
            </div>
            <div className="flex flex-col pr-6">
              <span className="font-sans-pro-regular color-636363-secondary-2 font-size-14 mb-6">
                {
                  props?.plans?.find((x) => x.title === "Express Care Service")
                    ?.description
                }
              </span>
              <span className="mb-4 font-sans-pro-regular color-150910-secondary-1">
                <span className="font-size-28 font-sans-pro-semi-bold font-semibold">
                  {
                    props?.plans?.find((x) => x.title === "Express Care Service")
                      ?.price
                  }
                </span>{" "}
                <span className="font-size-24">CAD</span>
                <span className="font-size-14"> /per consult</span>
              </span>
              <div className="flex flex-wrap flex-row">
                <div
                  onClick={() => {
                    const user = localStorage.getItem(
                      Params.LOCAL_STORAGE.PATIENT_INFO
                    );
                    if (user) {
                      window.location.href = process.env.REACT_APP_PATIENT_URL!;
                    } else {
                      Router.push("/pages/auth?type=login");
                    }
                  }}
                  className="cursor-pointer  h-14 mr-3 flex w-max items-center font-sans-pro-regular rounded-lg border border-transparent bg-color-c56183-eye-catching justify-center px-8 text-white lg:mb-0 mb-4"
                >
                  {"Get Consultation"}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pricing;
