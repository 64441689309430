import React, { useRef } from "react";
import { IProps } from "./types";
import Label from "@components/containers/home/label";
import { TBlogCards, BlogProps } from "./types";
import Slider from "react-slick";
import Router from "next/router";
import Params from "@utilities/param";

const BlogCards = (props: TBlogCards) => {
  return (
    <div className="w-72 rounded-lg shadow-lg p-6 bg-white flex flex-col my-6 mr-6 ml-1">
      <img
        loading="lazy"
        src={props.banner}
        alt={props.bannerAlt}
        className="w-full rounded-lg object-cover h-40 mb-6"
      />
      <a
        href={props.link}
        target="_blank"
        className="font-sans-pro-regular font-size-16 color-150910-secondary-1 mb-4 cursor-pointer"
      >
        {props.title}
      </a>
      <div className="flex flex-row items-center mb-6">
        <img
          loading="lazy"
          src={props.user.image}
          alt={`${props.user.username} image`}
          className="w-6 h-6 rounded-full mr-2"
        />
        <span className="font-sans-pro-regular font-size-12 color-150910-secondary-1">
          {props.user.username}
        </span>
      </div>
    </div>
  );
};
const Blogs = (props: BlogProps) => {
  const customSlider = useRef<Slider>();
  const settings = {
    className: "slider variable-width  w-full ",
    dots: false,
    arrows: false,
    infinite: false,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const nextSlide = () => {
    if (typeof customSlider != "undefined") {
      customSlider?.current?.slickNext();
    }
  };
  const prevSlide = () => {
    if (typeof customSlider != "undefined") {
      customSlider?.current?.slickPrev();
    }
  };

  return (
    <div className="flex flex-col lg:flex-row px-6 lg:px-12  xl:px-16 mb-10 lg:mb-10">
      <div className="flex w-full lg:w-1/3 flex-col mr-0 lg:mr-3 xl:mr-0">
        <Label title={"Blog"} />
        {props.readMore ? (
        <h3>
          <span className="mt-2 mb-5 font-sans-pro-regular text-2xl md:text-4xl color-150910-secondary-1">
            Read More About{" "}
            <span className="font-sans-pro-semi-bold font-semibold">
              {props.readMore}
            </span>{" "}
          </span>
        </h3>
        ) : (
          <span className="mt-2 mb-5 font-sans-pro-regular text-2xl md:text-4xl color-150910-secondary-1">
            Read the{" "}
            <span className="font-sans-pro-semi-bold font-semibold">
              latest blog posts
            </span>{" "}
            and get acquainted with{" "}
            <span className="font-sans-pro-semi-bold font-semibold">
              different skin conditions
            </span>
          </span>
        )}
        <p className="mb-10 font-sans-pro-regular color-636363-secondary-2 font-size-16">
          We discuss in details different skin conditions on a regular basis,
          make sure that you subscribe to our newsletter to receive our
          promotional updates.
        </p>
        <div className="flex flex-row flex-wrap items-center">
          <a href={`${props.categoryUri ?
            "https://remotederm.ca/blog/" + props.categoryUri :
            "https://remotederm.ca/blog/"
            }`} target="_blank">
            <div className=" h-14 flex w-max items-center font-sans-pro-regular rounded-lg border border-transparent justify-center px-8 text-white bg-color-c56183-eye-catching mr-4 mb-4 xl:mb-0">
              {"More Blog Post"}
            </div>
          </a>
          <div
            onClick={() => {
              const user = localStorage.getItem(
                Params.LOCAL_STORAGE.PATIENT_INFO
              );
              if (user) {
                window.location.href = process.env.REACT_APP_PATIENT_URL!;
              } else {
                Router.push("/pages/auth?type=login");
              }
            }}
            className="cursor-pointer  h-14 flex w-max items-center color-150910-secondary-1 font-sans-pro-regular rounded-lg border border-gray-200  justify-center px-8 text-white mb-4 lg:mb-0"
          >
            {"Get Consultation"}
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full lg:w-2/3">
        <div className="w-full flex flex-row justify-end mb-2 -mt-3">
          <span className="mr-2 cursor-pointer" onClick={prevSlide}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30 16C30 19.713 28.525 23.274 25.8995 25.8995C23.274 28.525 19.713 30 16 30C12.287 30 8.72602 28.525 6.10051 25.8995C3.475 23.274 2 19.713 2 16C2 12.287 3.475 8.72601 6.10051 6.1005C8.72602 3.475 12.287 2 16 2C19.713 2 23.274 3.475 25.8995 6.1005C28.525 8.72601 30 12.287 30 16ZM0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16ZM23 15C23.2652 15 23.5196 15.1054 23.7071 15.2929C23.8946 15.4804 24 15.7348 24 16C24 16.2652 23.8946 16.5196 23.7071 16.7071C23.5196 16.8946 23.2652 17 23 17H11.414L15.708 21.292C15.801 21.385 15.8747 21.4954 15.925 21.6168C15.9754 21.7383 16.0013 21.8685 16.0013 22C16.0013 22.1315 15.9754 22.2617 15.925 22.3832C15.8747 22.5046 15.801 22.615 15.708 22.708C15.615 22.801 15.5046 22.8747 15.3832 22.925C15.2617 22.9754 15.1315 23.0013 15 23.0013C14.8685 23.0013 14.7383 22.9754 14.6168 22.925C14.4954 22.8747 14.385 22.801 14.292 22.708L8.292 16.708C8.19887 16.6151 8.12499 16.5048 8.07458 16.3833C8.02416 16.2618 7.99821 16.1315 7.99821 16C7.99821 15.8685 8.02416 15.7382 8.07458 15.6167C8.12499 15.4952 8.19887 15.3849 8.292 15.292L14.292 9.292C14.385 9.19902 14.4954 9.12527 14.6168 9.07495C14.7383 9.02464 14.8685 8.99874 15 8.99874C15.1315 8.99874 15.2617 9.02464 15.3832 9.07495C15.5046 9.12527 15.615 9.19902 15.708 9.292C15.801 9.38498 15.8747 9.49535 15.925 9.61683C15.9754 9.73831 16.0013 9.86851 16.0013 10C16.0013 10.1315 15.9754 10.2617 15.925 10.3832C15.8747 10.5046 15.801 10.615 15.708 10.708L11.414 15H23Z"
                fill="#9CA3AF"
              />
            </svg>
          </span>
          <span className="cursor-pointer" onClick={nextSlide}>
            <svg
              width="32"
              height="34"
              viewBox="0 0 32 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 16.9205C2 20.7492 3.475 24.4211 6.1005 27.1284C8.72601 29.8358 12.287 31.3567 16 31.3567C19.713 31.3567 23.274 29.8358 25.8995 27.1284C28.525 24.4211 30 20.7492 30 16.9205C30 13.0917 28.525 9.4198 25.8995 6.71248C23.274 4.00516 19.713 2.4842 16 2.4842C12.287 2.4842 8.72601 4.00516 6.1005 6.71248C3.475 9.4198 2 13.0917 2 16.9205ZM32 16.9205C32 21.2962 30.3143 25.4926 27.3137 28.5867C24.3131 31.6808 20.2435 33.419 16 33.419C11.7565 33.419 7.68687 31.6808 4.68629 28.5867C1.68571 25.4926 0 21.2962 0 16.9205C0 12.5448 1.68571 8.34828 4.68629 5.2542C7.68687 2.16011 11.7565 0.421875 16 0.421875C20.2435 0.421875 24.3131 2.16011 27.3137 5.2542C30.3143 8.34828 32 12.5448 32 16.9205ZM9 15.8893C8.73478 15.8893 8.48043 15.9979 8.29289 16.1913C8.10536 16.3847 8 16.647 8 16.9205C8 17.1939 8.10536 17.4562 8.29289 17.6496C8.48043 17.843 8.73478 17.9516 9 17.9516H20.586L16.292 22.3774C16.199 22.4732 16.1253 22.5871 16.075 22.7123C16.0246 22.8376 15.9987 22.9718 15.9987 23.1074C15.9987 23.243 16.0246 23.3773 16.075 23.5025C16.1253 23.6278 16.199 23.7416 16.292 23.8375C16.385 23.9334 16.4954 24.0094 16.6168 24.0613C16.7383 24.1132 16.8685 24.1399 17 24.1399C17.1315 24.1399 17.2617 24.1132 17.3832 24.0613C17.5046 24.0094 17.615 23.9334 17.708 23.8375L23.708 17.6505C23.8011 17.5547 23.875 17.4409 23.9254 17.3157C23.9758 17.1904 24.0018 17.0561 24.0018 16.9205C24.0018 16.7848 23.9758 16.6505 23.9254 16.5252C23.875 16.4 23.8011 16.2862 23.708 16.1904L17.708 10.0034C17.615 9.90755 17.5046 9.8315 17.3832 9.77962C17.2617 9.72773 17.1315 9.70102 17 9.70102C16.8685 9.70102 16.7383 9.72773 16.6168 9.77962C16.4954 9.8315 16.385 9.90755 16.292 10.0034C16.199 10.0993 16.1253 10.2131 16.075 10.3384C16.0246 10.4636 15.9987 10.5979 15.9987 10.7335C15.9987 10.8691 16.0246 11.0033 16.075 11.1286C16.1253 11.2539 16.199 11.3677 16.292 11.4636L20.586 15.8893H9Z"
                fill="#9CA3AF"
              />
            </svg>
          </span>
        </div>
        <Slider
          //@ts-ignore
          ref={customSlider}
          {...settings}
        >
          {props?.blogs?.map((item: any, index: number) => (
            <div key={index} className="w-72">
              <BlogCards
                title={item.title?.rendered}
                banner={item.featured_media}
                bannerAlt={item['_embedded']['wp:featuredmedia'][0]['alt_text']}
                link={item.link}
                user={{
                  username: "Dr.Bahman Sotoodian",
                  image: "https://cdn.remotederm.ca/icons/home/dr.jpeg",
                }}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Blogs;
